
import { defineComponent, PropType } from 'vue'
import { Event } from '../types'

export default defineComponent({
  name: 'EventCard',
  
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true
    }
  }
})
