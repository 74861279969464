
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { Event } from '../types'

export default defineComponent<Event>({
  data() {
    return {
      categories: [
        'sustainability',
        'nature',
        'animal welfare',
        'housing',
        'education',
        'food',
        'community'
      ],
      event: this.freshEventObject() as Event
    }
  },

  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch('createEvent', this.event)
        this.freshEventObject()
        await this.$router.push({
          name: 'EventDetails',
          params: { id: this.event.id }
        })
      } catch (error) {
        this.$router.push({
          name: 'ErrorDisplay',
          params: { error }
        })
      }
    },

    freshEventObject(): Event {
      const id: string = uuidv4()
      const user: string = this.$store.state.user

      return {
        id,
        category: '',
        title: '',
        description: '',
        location: '',
        date: '',
        time: '',
        organizer: user
      }
    }
  }
})
