
import { defineComponent } from 'vue'
import { Event } from '../types'
import EventCard from '../components/EventCard.vue'

export default defineComponent({
  name: 'EventList',
  
  components: {
    EventCard
  },

  async created() {
   try {
     await this.$store.dispatch('fetchEvents')
   } catch (error) {
     this.$router.push({
       name: 'ErrorDisplay',
       params: { error }
     })
   }
  },

  computed: {
    events(): Event[] {
      return this.$store.state.events
    }
  }
})
